.CardBody {
  padding: 50px !important;
}

.Title {
  padding-bottom: 16px;
}

.ImgStyle {
  width: 350px !important;
}

.InformationDiv {
  display: flex;
}
