.Wrapper {
  display: inline-block;
  width: 100%;
}

.CustomMenu {
  z-index: 4 !important;
}

.CustomSelect {
  border: 1px solid hsl(0, 0%, 80%) !important;
  width: 100%;
  color: var(--primary-red) !important;
  border-radius: 2px !important;
  text-transform: uppercase;
  box-shadow: none !important;
  background: white !important;
  font-size: 16px;
  margin-top: 35px;
}

.CustomSelect:hover {
  border: 1px solid hsl(0, 0%, 80%) !important;
}

.CustomOption {
  background-color: transparent !important;
  color: var(--primary-red) !important;
  text-align: left;
  text-transform: uppercase;
}

.CustomOption:hover {
  background-color: rgba(0, 0, 0, 0.08) !important;
  color: var(--primary-red) !important;
}

.DropdownIcon {
  color: var(--primary-red);
  transform: rotate(90deg);
}

@media (min-width: 960px) {
  .CustomSelect {
    font-size: 16px;
  }
}
