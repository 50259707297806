:root {
  --primary-red: #ec1f39;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  background: #eee;
}

:global(.Feedback) {
  margin-top: 12%;
  text-align: center;
  font-size: 24px;
}    
  
