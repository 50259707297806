.ButtonIcon {
  margin-right: 0.5rem;
}

.Button span {
  font-size: 1rem;
  color: #fff;
  display: flex;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
}

.Button {
  margin-left: 1rem;
  color: #fff;
  font-size: 1rem;
  text-decoration: none;
}

.Button:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}
