.TextField,
.Textarea {
  width: 90%;
}

.Input {
  width: 100% !important;
}

.Recaptcha {
  width: 98%;
  margin: 0 auto;
  margin-top: 15px;
}

.Dense {
  margin-top: 16px !important;
}

.Textarea {
  width: 90%;
}

.Checkbox {
  margin-left: 5% !important;
}

.SelectContainer {
  width: 80%;
}

.Invalid {
  border: 1px solid red;
  background-color: salmon;
}

.ValidationError {
  color: red;
  margin: 5px 0;
}

.OutlinedSelect {
  width: 100% !important;
}

.ReactDatePicker > div {
  display: block !important;
}

.ReactDatePicker > div > div {
  width: 100%;
}

.Dense {
  margin-top: 16px !important;
  z-index: 0;
}

.FullWidth {
  width: 100% !important;
}

.InputPlaceholder::placeholder {
  color: black;
  outline: 0;
  opacity: 1 !important;
}

.Label {
  color: rgba(0, 0, 0, 1);
  padding: 0 0 0.9rem 0;
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  line-height: 1;
}

.DatePickerMainDiv > div {
  display: block !important;
}

.DatePickerMainDiv > div > div {
  width: 100%;
}

@media (min-width: 960px) {
  .TextField {
    width: 80%;
  }

  .Recaptcha {
    width: 80%;
  }

  .SmallInput {
    height: 25px !important;
    padding: 7px;
    font-size: 12px;
  }

  .Label {
    padding-bottom: 0.5rem;
  }
}

@media (min-width: 1600px) {
  .SmallInput {
    height: 19px !important;
    padding: 15px 14px;
    font-size: 1rem;
  }

  .Label {
    padding-bottom: 0.8rem;
  }
}
