.LoadingContainer {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.EditorContainer {
  margin: 1rem 0 !important;
  width: 80%;
}

.SubmitButton {
  margin: 1rem auto !important;
}

.TabsContainer {
  background-color: #fff;
  padding-bottom: 1rem;
}

.TabContent {
  text-align: center;
  width: 100%;
}
