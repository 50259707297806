.TextEditor h1 {
  text-align: center;
}

.CustomDialog {
  max-width: 80% !important;
}

.Toolbar {
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
  background: #cccccc80;
}

:global(div.DraftEditor-root) {
  background-color: #ffffff;
  margin:auto;
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
  height:300px;
  overflow-y: scroll;
  padding:20px;
  font-size: 16px;
  font-family: 'calibri', sans-serif;
}