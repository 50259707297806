.EditButton {
  padding: 2px !important;
  color: #fffffff0 !important;
  margin-left: 20px !important;
}

.SectionDescriptionTitle {
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
}

.SectionDescription {
  margin-bottom: 40px;
  margin-top: 20px;
}

.SectionDescription p {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
