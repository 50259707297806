.H2Image {
  margin: 2rem 0 0 0 !important;
}

.Image {
  width: 350px !important;
}

.ImageInput {
  font-size: 1rem !important;
  margin: 1rem 0 !important;
}

.SubmitButton {
  margin: 0 auto 25px auto !important;
}

.FormControl {
  width: 100%;
  text-align: left;
  display: flex;
}
