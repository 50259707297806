.Auth {
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100vh;
}
.AuthFormContainer{
  display: table;
  width: 100%;
  height: 100%; 
}

.AuthFormSubContainer{
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.AuthForm {
  margin-left: auto;
  margin-right: auto;
  max-width: 70%;
  background: white;
  padding: 40px;
  text-align: center;
}

.SubmitButton {
  padding: 10px 40px !important;
  margin-top: 40px !important;
  color: #fff !important;
}

.ErrorMessage {
  color: red;
}

@media (min-width: 960px) {
  .AuthForm {
    max-width: 40%;
  }
}

.Logo {
  max-width: 350px;
  margin: 32px;
}